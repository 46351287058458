import WrinkleLocationsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/wrinkle-locations/WrinkleLocationsTemplate';

import { GENDER, WRINKLES_GROUP } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/WrinkleLocations/WrinkleLocationsTemplate'
};

const createStory = props => () => ({
  components: { WrinkleLocationsTemplate },
  storyProps: props,
  wrapStyles: {
    width: '689px',
    maxWidth: '100%',
    margin: 'auto'
  },
  template: `<div :style='$options.wrapStyles'>
    <wrinkle-locations-template v-bind="$options.storyProps" />
  </div>`
});

export const Female = createStory({
  gender: GENDER.FEMALE,
  wrinkles: {
    [WRINKLES_GROUP.FORHEAD]: '0',
    [WRINKLES_GROUP.FROWN]: '1',
    [WRINKLES_GROUP.CROWS_FEET]: '2',
    [WRINKLES_GROUP.BUNNY]: '0',
    [WRINKLES_GROUP.TEAR_TROUGHS]: '0',
    [WRINKLES_GROUP.NASOLABIAL_FOLDS]: '0',
    [WRINKLES_GROUP.VERTICAL_LIP]: '0',
    [WRINKLES_GROUP.MOUTH_FROWN]: '3',
    [WRINKLES_GROUP.MARIONETTE]: '0',
    [WRINKLES_GROUP.CHIN_CREASE]: '2',
    [WRINKLES_GROUP.NECK]: '0'
  }
});
